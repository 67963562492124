import React, { useEffect, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import {
  Error400,
  Error401,
  Error403,
  Error404,
  Error500,
  Error503,
} from "./Pages/ErrorPage";
// import Login from "./Pages/AuthPage/Login";

// s.Bookmark
import Bookmark from "./Pages/Bookmark/Bookmark";
// e.Bookmark

// s.manajemen user
import ManajemenUser from "./Pages/ManajemenUser/ManajemenUser";
// e.manajemen user

// s.manajemen role
import ManajemenRole from "./Pages/ManajemenRole/ManajemenRole";
import TambahRole from "./Pages/ManajemenRole/TambahRole";
import EditRole from "./Pages/ManajemenRole/EditRole";
// e.manajemen role

// s.bagan
// import EditBagan from "./Pages/Bagan/EditBagan";
// e.bagan

// s.report
import Report from "./Pages/Report/Report";
// e.report

// s.judul
import Judul from "./Pages/Judul/Judul";
// e.judul

// s.manajemen grup
import ManajamenGroup from "./Pages/Judul/ManajemenGroup";
// e.manajemen grup

// s.profile
import Profile from "./Pages/Profile/Profile";
// e.profile

// s.master data
import MasterData from "./Pages/MasterData/MasterData";
// e.master data

// all data kandidat artis
import TalentSinemart from "./Pages/TalentSinemart/TalentSinemart";
import RiwayatFilterAll from "./Pages/TalentSinemart/RiwayatFilter";
// all data kandidat artis

//ARTIS
import Artis from "./Pages/Artis/Artis";
import RiwayatFilterArtis from "./Pages/Artis/RiwayatFilter";
import LaporanArtis from "./Pages/Artis/Laporan";
import DetailArtis from "./Pages/Artis/DetailArtis";
import EditBiografiArtis from "./Pages/Artis/EditBiografi";

//KANDIDAT
import KandidatArtis from "./Pages/KandidatArtis/KandidatArtis";
import RiwayatFilterKandidatArtis from "./Pages/KandidatArtis/RiwayatFilter";
import TambahKandidatArtis from "./Pages/KandidatArtis/TambahKandidatArtis";
import DetailKandidatArtis from "./Pages/KandidatArtis/DetailKandidatArtis";
import EditBiografiKandidatArtis from "./Pages/KandidatArtis/EditBiografi";

//MANAJEMEN JUDUL
import Casting from "./Pages/Judul/Manajemen/Casting/Casting";
import DetailCasting from "./Pages/Judul/Manajemen/Casting/DetailCasting";
import Bagan from "./Pages/Judul/Manajemen/Bagan/Bagan";
import EditBagan from "./Pages/Judul/Manajemen/Bagan/EditBagan";
import DetailBagan from "./Pages/Judul/Manajemen/Bagan/DetailBagan";

// Logout
import LogoutDb from "./Pages/AuthPage/LogoutFromDb";
import LogoutPm from "./Pages/AuthPage/LogoutFromPm";

// import 'bootstrap/dist/css/bootstrap.min.css';
import "tabler-react/dist/Tabler.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "boxicons/css/boxicons.min.css";
import "antd/dist/antd.min.css";
import "react-datepicker/dist/react-datepicker.css";
import Store from "./Context/Store";
import Loader from "react-loader-spinner";
import Permission from "./Context/Permission";
import Alert from "./Context/Alert";
// import axios from "axios";
import { ArtistSp } from "./Pages/ArtistSp/ArtistSp";
import DetailArtisSP from "./Pages/ArtistSp/DetailArtisSP";
import EditBiografi from "./Pages/ArtistSp/EditBiografi";

function App() {
  const [loadingGetToken, setLoadingGetToken] = useState("idle");
  var createGuest = require("cross-domain-storage/guest");

  useEffect(() => {
    setLoadingGetToken("pending");
    const bazStorage = createGuest(process.env.REACT_APP_LOGIN_TOKEN_URL);

    bazStorage.get("access_data", async (error, value) => {
      let getValue;
      if (value) {
        getValue = JSON.parse(value);
      }
      if (getValue) {
        if (getValue?.value !== null) {
          getValue?.value.map((item, i) => {
            localStorage.setItem("token", item.access_token);
            localStorage.setItem("username", item.username);
            localStorage.setItem("role", item.role);
            localStorage.setItem("image", item.image);
            localStorage.setItem("email", item.email);
          });
        }
        // await getPermission();
        setLoadingGetToken("resolved");
      } else {
        window.location.replace(process.env.REACT_APP_LOGIN_TOKEN_URL);
      }
      if (error) {
        window.location.replace(process.env.REACT_APP_LOGIN_TOKEN_URL);
      }
    });
  }, []);

  // async function getPermission() {
  //   let token = localStorage.getItem("token");
  //   if (!token) return;

  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_GATEWAY_URL}api/user/users/get-permission`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     const modulPermission = response.data.data?.find(
  //       (m) => m.name_moduls === "TALENT"
  //     );
  //     if (!modulPermission) {
  //       throw new Error("No permission for TALENT module.");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     sessionStorage.clear();
  //     localStorage.clear();
  //     window.location.replace(process.env.REACT_APP_LOGIN_TOKEN_URL);
  //   }
  // }

  return (
    <React.StrictMode>
      {loadingGetToken === "resolved" ? (
        <Alert>
          <Permission>
            <Store>
              <Router>
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/artis" />
                  </Route>

                  {/* S.Profile */}
                  <Route exact path="/profile" component={Profile}></Route>
                  {/* E.Profile */}

                  {/* S.Manajemen User */}
                  <Route
                    exact
                    path="/manajemen-user"
                    component={ManajemenUser}
                  ></Route>
                  {/* E.Manajemen User */}

                  {/* S.Manajemen Role */}
                  <Route
                    exact
                    path="/manajemen-role"
                    component={ManajemenRole}
                  ></Route>
                  <Route
                    exact
                    path="/tambah-role"
                    component={TambahRole}
                  ></Route>
                  <Route
                    exact
                    path="/edit-role/:idRole"
                    component={EditRole}
                  ></Route>
                  {/* E.Manajemen Role */}

                  {/* S.Bookmark */}
                  <Route exact path="/bookmark" component={Bookmark}></Route>
                  {/* E.Bookmark */}

                  {/* S.Judul */}
                  <Route exact path="/judul" component={Judul}></Route>
                  <Route
                    exact
                    path="/manajemen-group"
                    component={ManajamenGroup}
                  ></Route>

                  {/* S.Casting */}
                  <Route exact path="/casting/:id" component={Casting}></Route>
                  <Route
                    exact
                    path="/detail-casting/:title_id/:id"
                    component={DetailCasting}
                  ></Route>
                  {/* E.Casting */}
                  <Route exact path="/bagan/:id" component={Bagan}></Route>
                  <Route
                    exact
                    path="/bagan/detail/:id"
                    component={DetailBagan}
                  ></Route>
                  <Route exact path="/bagan/edit/:id" component={EditBagan} />
                  {/* s.Bagan */}

                  {/* E.Judul */}

                  {/* S.Report */}

                  <Route exact path="/report">
                    <Redirect to="/report/grade" />
                  </Route>

                  <Route exact path="/report/grade" component={Report}></Route>
                  <Route
                    exact
                    path="/report/prospect"
                    component={Report}
                  ></Route>
                  {/* E.Report */}

                  {/* S.Artis */}
                  <Route exact path="/artis" component={Artis}></Route>
                  <Route
                    exact
                    path="/artis/riwayat-filter"
                    component={RiwayatFilterArtis}
                  ></Route>
                  <Route
                    exact
                    path="/artis/detail/:id"
                    component={DetailArtis}
                  />
                  <Route
                    exact
                    path="/artis/edit-biografi/:id"
                    component={EditBiografiArtis}
                  />
                  <Route
                    exact
                    path="/artis/laporan"
                    component={LaporanArtis}
                  ></Route>
                  {/* E.Artis */}

                  {/* S.Kandidat Artis */}
                  <Route
                    exact
                    path="/kandidat-artis"
                    component={KandidatArtis}
                  ></Route>
                  <Route
                    exact
                    path="/kandidat-artis/riwayat-filter"
                    component={RiwayatFilterKandidatArtis}
                  ></Route>
                  <Route
                    exact
                    path="/kandidat-artis/detail/:id"
                    component={DetailKandidatArtis}
                  ></Route>
                  <Route
                    exact
                    path="/kandidat-artis/edit-biografi/:id"
                    component={EditBiografiKandidatArtis}
                  />
                  <Route
                    exact
                    path="/kandidat-artis/tambah"
                    component={TambahKandidatArtis}
                  ></Route>
                  {/* E.Kandidat Artis */}

                  {/* S.Master Data */}
                  <Route
                    exact
                    path="/master-data"
                    component={MasterData}
                  ></Route>
                  {/* E.Master Data */}

                  {/* ALl data kandidat artis */}
                  <Route
                    exact
                    path="/all-talent"
                    component={TalentSinemart}
                  ></Route>

                  <Route
                    exact
                    path="/all-sinemart/riwayat-filter"
                    component={RiwayatFilterAll}
                  ></Route>

                  <Route exact path="/artis-sp" component={ArtistSp}></Route>
                  <Route
                    exact
                    path="/artist-sp-detail/:id"
                    component={DetailArtisSP}
                  ></Route>
                  <Route
                    exact
                    path="/artis-sp/edit-biografi/:id"
                    component={EditBiografi}
                  />

                  {/* ALl data kandidat artis */}

                  {/* S.Logout */}
                  <Route exact path="/logout-db" component={LogoutDb}></Route>
                  <Route exact path="/logout-pm" component={LogoutPm}></Route>
                  {/* E.Logout */}

                  {/* <Route exact path="/login" component={LoginPage} /> */}
                  {/* <Route exact path="/login" component={Login} /> */}

                  <Route exact path="/400" component={Error400} />
                  <Route exact path="/401" component={Error401} />
                  <Route exact path="/403" component={Error403} />
                  <Route exact path="/404" component={Error404} />
                  <Route exact path="/500" component={Error500} />
                  <Route exact path="/503" component={Error503} />
                  <Route component={Error404} />
                </Switch>
              </Router>
            </Store>
          </Permission>
        </Alert>
      ) : (
        <Loader
          visible={true}
          className="loaders"
          type="ThreeDots"
          color="#ED1C24"
          height="100"
          width="100"
        />
      )}
    </React.StrictMode>
  );
}

export default App;
