import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const ContextPermission = createContext(null);

const Permission = ({ children }) => {
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const loginUrl = process.env.REACT_APP_LOGIN_TOKEN_URL;
  const [state, setState] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const userMenu = sessionStorage.getItem("userMenu");

    if (userMenu) {
      try {
        const storedData = JSON.parse(userMenu);
        setState(storedData);
      } catch (error) {
        console.error("Failed to parse userMenu from sessionStorage:", error);
        getPermission();
      }
    } else {
      getPermission();
    }
  }, []);

  const getPermission = async () => {
    if (!token) {
      redirectToLogin();
      return;
    }

    try {
      // Get user menu
      const res = await axios.get(`${urlMicroservice}user/menu`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const userMenu = res.data.data;
      setState(userMenu);
      sessionStorage.setItem("userMenu", JSON.stringify(userMenu));

      // Validate TALENT module permission
      const response = await axios.get(
        `${process.env.REACT_APP_GATEWAY_URL}api/user/users/get-permission`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const hasTalentPermission = response.data.data?.some(
        (m) => m.name_moduls === "TALENT"
      );

      if (!hasTalentPermission) {
        throw new Error("No permission for TALENT module.");
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
      handleAuthError(error);
    }
  };

  const redirectToLogin = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace(loginUrl);
  };

  const handleAuthError = (error) => {
    if (error.response?.status === 401) {
      window.open(loginUrl, "_parent");
    } else {
      redirectToLogin();
    }
  };

  return (
    <ContextPermission.Provider value={state}>
      {state ? children : null}
    </ContextPermission.Provider>
  );
};

export default Permission;
